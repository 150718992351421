import React, { useEffect , useState} from 'react';
import { Box, Grid, Button, Typography, TextField, Divider, Card, Avatar, FormControl, Select, MenuItem } from '@mui/material';
import CustomTable from '../../components/table/CustomerTable';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { deepPurple } from '@mui/material/colors';
import { getcustomer } from '../../slices/customer';
import Popup from '../../components/popup/Popup';

const columns = [
  { id: 'name', label: 'Custome Name', minWidth: 150, align:'start' },
  { id: 'customer_phone_mobile', label: 'Phone', minWidth: 150, align:'start' },
  { id: 'city', label: 'Location', minWidth: 150, align:'center' },
  { id: 'date', label: 'Customer From', minWidth: 150, align:'center' },
  { id: 'customer_type', label: 'Customer Type', minWidth: 150, align:'center' },
 ];

const Customer = ({setLoader, show, dealerId, viewData}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {customers: cusData} = useSelector((state) => state.customer);

  const [status, setStatus] = React.useState('Name');

  const [enquiryData, setFilteredData] = React.useState(cusData);

  const rows1 = enquiryData.map((va)=> {
    return{...va, name: `${va.title}  ${va.first_name}  ${va.last_name}`, city: va.billing_address.city, date: `${new Date(va.created_at).getDate()}-${new Date(va.created_at).getMonth()+1}-${new Date(va.created_at).getFullYear()}`}
  })

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
  };

  
  const handleSearch = (query) => {
    if(status === 'Name'){
      const filtered = cusData.filter(item => (item.first_name+item.last_name).includes(query));
      setFilteredData(filtered);
    }
    if(status === 'Email'){
      const filtered = cusData.filter(item => (item.email).includes(query));
      setFilteredData(filtered);
    }
    if(status === 'Phone'){
      const filtered = cusData.filter(item => ((item.customer_phone_mobile).toString()).includes(query));
      setFilteredData(filtered);
    }
  };

  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.customer);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getcustomer(dealerId))
      .then((response) => {
        console.log(response);
        setFilteredData(response.payload.customerdata.data);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div>
      <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={4} sx={{paddingTop: 0}}>

          </Grid>
          <Grid item xs={8} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', gap: 2, alignItems:'center'}}>
          <FormControl size='small' sx={{ width: 100 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="Name">Name</MenuItem>
          <MenuItem value="Phone">Phone</MenuItem>
          <MenuItem value="Email">Email</MenuItem>
        </Select>
      </FormControl>
          <SearchButton handleSearch={handleSearch} setFilteredData={setFilteredData} placeholder="Search Customer"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{mt: 2}}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable viewData={viewData} dealerId={dealerId} columns={columns} rows={enquiryData ? rows1 : []}/>
        </Grid>
        </Grid>
      </Box>
      </Box>
    </div>
  );
}

export default Customer;