import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Card, CardContent, Grid, colors } from '@mui/material';
import SearchButton from '../search/Search';
import CustomTable1 from '../table/Purtable';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns1 = [
  { id: 'purchase_id', label: 'Purchase No', minWidth: 150, align:'start' },
  { id: 'purchase_by', label: 'Purchase By', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns2 = [
  { id: 'purchase_id', label: 'Purchase No', minWidth: 150, align:'start' },
  { id: 'purchase_by', label: 'Purchase By', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns3 = [
  { id: 'purchase_id', label: 'Purchase No', minWidth: 150, align:'start' },
  { id: 'purchase_by', label: 'Purchase By', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({ value, setValue, vehicleApiData, spareApiData, accessoriesApiData}) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const rows1 = vehicleApiData?.map((va)=>{
    return{
      product_type: va.product_type,
      purchase_id: va.purchase_id,
      purchase_by:va.organization_name,
      purchase_date: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
      qty: va.item_list.length,
      status:  !va.order_created ? 'Not Created' : !va.order_approved ? "Pending" : !va.payment_done ? "Not Paid" : !va.payment_verification ? "Pay Verify" : !va.assign_products ? "Not Assigned" : !va.dispatch_products ? "Not Dispatched" : !va.out_for_delivery ? "Not Delivered" : "Delivered"   
    }
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle Model" {...a11yProps(0)} sx={{fontWeight:'bold'}} />
          <Tab label="Spare Parts" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <Box sx={{mb: 3}}>
      <SearchButton placeholder="Search Purchase Order"/>
      </Box>
      <CustomTable1 columns={columns1} rows={rows1?.filter(obj =>{return obj.product_type === 'Vehicle'})}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <Box sx={{mb: 3}}>
      <SearchButton placeholder="Search Purchase Order"/>
      </Box>
      <CustomTable1 columns={columns1} rows={rows1?.filter(obj =>{return obj.product_type === 'Spare'})}/>      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Box sx={{mb: 3}}>
      <SearchButton placeholder="Search Purchase Order"/>
      </Box>
      <CustomTable1 columns={columns1} rows={rows1?.filter(obj =>{return obj.product_type === 'Accessory'})}/>      </CustomTabPanel>
    </Box>
  );
}