import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/SpareTable';
import CustomTable3 from '../table/AccessTable';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import './Tabs.css';
import { Button, Card, CardContent, Grid, colors } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deletevarintbyve, deletevehicle } from '../../slices/product';
import { getproductstock } from '../../slices/inventory';
import no_data from '../../assets/images/no_data.png';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: 'product_img', label: 'Product Image', minWidth: 150, align:'start' },
  { id: 'vehicle_name', label: 'Vehicle Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'item_type', label: 'Type', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns1 = [
  { id: 'product_img', label: 'Product Image', minWidth: 150, align:'start' },
  { id: 'vehicle_name', label: 'Vehicle Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'company', label: 'Company', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({ setLoader, value, setValue, vehicleApiData, spareApiData, accessoriesApiData}) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];

  const rows1 = vehicleApiData?.map((va)=>{
    return{
      id: va.id,
      product_img: va.product_img,
      product_name: va.product_name,
      colours: (va?.variant?.map((item, index) =>{return JSON.parse(item.color_details).map(obj => obj.code)}))?.flat(),
      type: va.product_type,
      novariants: va?.variant?.length,
      availability: "Available"
    }
  });

  const rows2 = spareApiData?.map((va)=>{
    return{...va, vehicle_img: va?.vehicle?.product_img, vehicle_name: va?.vehicle?.product_name
    }
  });

  const rows3 = accessoriesApiData?.map((va)=>{
    return{...va, vehicle_img: va?.vehicle?.product_img, vehicle_name: va?.vehicle?.product_name
    }
  });

  const handledeleteProduct = (vehicle_id) =>{
    const verifyDelete = vehicleStockData.filter(va => va.vehicle_id === vehicle_id);
    if(verifyDelete.length>0){
        window.alert("Product already assigned your not able delete that product.")
    }
    else{
      setLoader(true)
      dispatch(deletevehicle({vehicle_id}))
      dispatch(deletevarintbyve({vehicle_id}))
      .then(() => {
        setLoader(false);
        window.location.reload();
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }

  React.useEffect(()=>{
    setLoader(true)
      dispatch(getproductstock())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle Model" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
          <Tab label="Spare Parts" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {rows1.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
              :
        rows1.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={1}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Colours</Typography>
                  <Box sx={{display:'flex', gap:'8px'}}>
                  {ec?.colours?.map((ka, index)=>{
                    return(
                      <p key={index} style={{width:'25px', height:'25px', borderRadius:'30px', background:`${ka}`, margin: 0}}></p>
                    )
                  })}
                  </Box>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Type</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.type}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Variants</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.novariants}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Availability</Typography>
                  <Typography sx={{color: '#2e7d32', fontWeight:'bold'}}>{ec.availability}</Typography>
              </Grid>
              <Grid item xs={1} >
              <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
              <Link to={`/product/updateproduct/${ec.id}`} state={{productData: vehicleApiData.filter(va => va.id === ec.id)[0]}}>
              <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 1,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                        </Link>
                                        <Button
                                          sx={{
                                            background: "#dc0707",
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={() => handledeleteProduct(ec.id)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
                                        </Box>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CustomTable columns={columns} rows={spareApiData ? rows2 : []}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <CustomTable3 columns={columns1} rows={accessoriesApiData ? rows3 : []}/>
      </CustomTabPanel>
    </Box>
  );
}