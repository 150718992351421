import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import CustomTable1 from '../../components/table/Wartab';
import { getwarranty } from '../../slices/warranty';

const columns = [
    { id: 'warranty_id', label: 'Warranty ID', minWidth: 150, align:'start' },
    { id: 'dealer_name', label: 'Dealer Name', minWidth: 150, align:'start' },
    { id: 'customer_name', label: 'Customer Name', minWidth: 150, align:'center' },
    { id: 'select_vehicle', label: 'Model', minWidth: 150, align:'center' },
    { id: 'item', label: 'Item', minWidth: 150, align:'center' },
    { id: 'item_no', label: 'Item No', minWidth: 150, align:'center' },
    { id: 'status', label: 'Status', minWidth: 150, align:'center' },
    // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: true}] },
  ];

const Warranty = ({setLoader, show}) => {

  const {warranty: warrantyData} = useSelector((state) => state.warranty) || [];

  const rows1 =  warrantyData?.map((va)=>{
    return{
      ...va,
      status:  !va.warranty_created ? 'Not Created' : !va.claim_verification ? "Verify Pending" : !va.in_transit ? "Waiting Transit" : !va.inspection ? "Inspection Pending" : !va.under_service_replace ? "Waiting S/R " : !va.returning ? "Waiting Dispatched" : !va.out_for_delivery ? "Not Delivered" : "Delivered"      }
  });

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getwarranty())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            Received Warranty
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Here"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable1 columns={columns} rows={rows1 || []} />
      </Box>
    </div>
  );
}

export default Warranty;