import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomTable1 from '../table/Wartab';
import Customer from '../../pages/customers/Customer';
import Warranty from '../../pages/dealer/Warranty';
import SearchButton from '../search/Search';
import { getpurchasebydealerid } from '../../slices/orders';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: 'salutation', label: 'Salutation', minWidth: 200, align:'start' },
  { id: 'name', label: 'Name', minWidth: 100, align:'start' },
  { id: 'designation', label: 'Designation', minWidth: 150, align:'center' },
  { id: 'email', label: 'Email', minWidth: 150, align:'center' },
  { id: 'phone_number', label: 'Phone Number', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns1 = [
  { id: 'purchase_id', label: 'Purchase ID', minWidth: 150, align:'start' },
  { id: 'product_type', label: 'Product Type', minWidth: 150, align:'start' },
  { id: 'purchase_date', label: 'Purchase Date', minWidth: 150, align:'center' },
  { id: 'qty', label: 'Quantity', minWidth: 150, align:'center' },
  { id: 'po_secret', label: 'PO Secret', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];


export default function BasicTabs({viewData, setLoader, cusId}) {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();

  const [status, setStatus] = React.useState('All');

  const [type, setType] = React.useState('All');

  const {purchase: vehicleApiData} = useSelector((state) => state.order) || [];

  const [filteredData, setFilteredData] = React.useState(vehicleApiData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.clear();
  };

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
    if(event.target.value === 'All'){
      setFilteredData(vehicleApiData);
    }
    else{
      const filtered = vehicleApiData.filter(va =>  (va.out_for_delivery ? 'Received' : va.dispatch_products ? "Dispatched" : va.assign_products ? "Products Assigned" : va.payment_verification ? "Payment Verified" : va.payment_done ? "Payment Done" : va.order_approved ? "Approved" : va.order_created ? "Pending" : "Not Created" )=== event.target.value);
      setFilteredData(filtered);
    }
  };

  
  const handleTypeChange = (event) => {
    setType(event.target.value);
    if(event.target.value === 'All'){
      setFilteredData(vehicleApiData);
    }
    if(event.target.value === 'Vehicle'){
      const filtered = vehicleApiData.filter(obj =>{return obj.product_type === 'Vehicle'});
      setFilteredData(filtered);
    }
    if(event.target.value === 'Spare'){
      const filtered = vehicleApiData.filter(obj =>{return obj.product_type === 'Spare'});
      setFilteredData(filtered);
    }
    if(event.target.value === 'Accessory'){
      const filtered = vehicleApiData.filter(obj =>{return obj.product_type === 'Accessory'});
      setFilteredData(filtered);
    }
  };

  React.useEffect(()=>{
    setFilteredData(vehicleApiData);
  }, [vehicleApiData])


  
  const rows1 = filteredData?.map((va)=>{
    return{
      purchase_id: va.purchase_id,
      product_type:va.product_type,
      purchase_date: `${(new Date(va.created_at)).getDate()} / ${(new Date(va.created_at)).getMonth()+1} / ${(new Date(va.created_at)).getFullYear()}`,
      qty: va.item_list.length,
      po_secret: va.po_secret,
      status: va.out_for_delivery ? 'Received' : va.dispatch_products ? "Dispatched" : va.assign_products ? "Products Assigned" : va.payment_verification ? "Payment Verified" : va.payment_done ? "Payment Done" : va.order_approved ? "Approved" : va.order_created ? "Pending" : "Not Created"  
    }
  });

  const handleSearch = (query) => {
    const filtered = vehicleApiData.filter(item => item.purchase_id.includes(query));
    setFilteredData(filtered);
  };

  React.useEffect(()=>{
    setLoader(true)
      dispatch(getpurchasebydealerid(cusId))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Customer" {...a11yProps(0)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Vehicle Sold" {...a11yProps(1)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Vehicle Stock" {...a11yProps(2)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Purchase Order" {...a11yProps(3)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Warranty Claim" {...a11yProps(4)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Address Details" {...a11yProps(5)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Document" {...a11yProps(6)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Contact Person" {...a11yProps(7)}  sx={{fontWeight:'bold'}}/>
          <Tab label="Bank Details" {...a11yProps(8)}  sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Customer viewData={viewData} dealerId={cusId} setLoader={setLoader}/>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
                  <Customer viewData={viewData} dealerId={cusId} setLoader={setLoader}/>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
          <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Typography variant='h6'>
                  Vehicle Stock            
                  </Typography>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
              <Box sx={{mb: 3, display:'flex', alignItems:'center', justifyContent:'end'}}>
      <SearchButton handleSearch={handleSearch} placeholder="Search By Purchase ID" setFilteredData={setFilteredData}/>
      <FormControl size='small' sx={{ width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="PO Rejected">PO Rejected</MenuItem>
          <MenuItem value="Payment Done">Payment Done</MenuItem>
          <MenuItem value="Payment Verified">Payment Verified</MenuItem>
          <MenuItem value="Payment Rejected">Payment Rejected</MenuItem>
          <MenuItem value="Products Assigned">Products Assigned</MenuItem>
          <MenuItem value="Dispatched">Dispatched</MenuItem>
          <MenuItem value="Received">Received</MenuItem>
        </Select>
      </FormControl>
      <FormControl size='small' sx={{ml: 2, width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          onChange={handleTypeChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="All">Product Type</MenuItem>
          <MenuItem value="Vehicle">Vehicle</MenuItem>
          <MenuItem value="Spare">Spare</MenuItem>
          <MenuItem value="Accessory">Accessory</MenuItem>
        </Select>
      </FormControl>
     </Box>
      <CustomTable1 columns={columns1} rows={rows1}/>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                 <Warranty setLoader={setLoader} dealerId={cusId}/>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
          <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Typography variant='h6'>
                  Billing Address                
                  </Typography>
                  <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>Country / Region</Typography>
                      <Typography sx={{mb: 1}}>State</Typography>
                      <Typography sx={{mb: 1}}>Address</Typography>
                      <Typography sx={{mb: 1}}>City</Typography>
                      <Typography sx={{mb: 1}}>Pin Code</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>{viewData?.billing_address && JSON.parse(viewData?.billing_address).country}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.billing_address && JSON.parse(viewData?.billing_address).state}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.billing_address && JSON.parse(viewData?.billing_address).address}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.billing_address && JSON.parse(viewData?.billing_address).city}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.billing_address && JSON.parse(viewData?.billing_address).pincode}</Typography>
                    </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={6}>
                  <Typography variant='h6'>
                  Shipping Address            
                  </Typography>
                  <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>Country / Region</Typography>
                      <Typography sx={{mb: 1}}>State</Typography>
                      <Typography sx={{mb: 1}}>Address</Typography>
                      <Typography sx={{mb: 1}}>City</Typography>
                      <Typography sx={{mb: 1}}>Pin Code</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>{viewData?.shipping_address && JSON.parse(viewData?.shipping_address).country}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.shipping_address && JSON.parse(viewData?.shipping_address).state}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.shipping_address && JSON.parse(viewData?.shipping_address).address}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.shipping_address && JSON.parse(viewData?.shipping_address).city}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.shipping_address && JSON.parse(viewData?.shipping_address).pincode}</Typography>
                    </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
      <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography variant='h6'>
                  Document Details              
                  </Typography>
              </Grid>
              {viewData?.documents && JSON.parse(viewData?.documents).map((ab, index)=>{
                return(
                  <Grid key={index} item xs={2}>
                      <img src={ab} alt='doc' width={200}></img>
                  </Grid>
                )
              })}
              </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
      <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography variant='h6'>
                  Contact person               
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTable1 columns={columns} rows={viewData?.contact_person && JSON.parse(viewData?.contact_person)}/>
              </Grid>
              </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={8}>
      <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Typography variant='h6'>
                  Bank Details               
                  </Typography>
                  <Grid container spacing={2} sx={{mt: 1}}>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>Account Type</Typography>
                      <Typography sx={{mb: 1}}>Beneficiary Name</Typography>
                      <Typography sx={{mb: 1}}>Bank Name</Typography>
                      <Typography sx={{mb: 1}}>Account Number</Typography>
                      <Typography sx={{mb: 1}}>IFSC code</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{mb: 1}}>{viewData?.bank_details && JSON.parse(viewData?.bank_details).account_type}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.bank_details && JSON.parse(viewData?.bank_details).benef_name}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.bank_details && JSON.parse(viewData?.bank_details).bank_name}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.bank_details && JSON.parse(viewData?.bank_details).acc_no}</Typography>
                      <Typography sx={{mb: 1}}>{viewData?.bank_details && JSON.parse(viewData?.bank_details).ifsc}</Typography>
                    </Grid>
                  </Grid>
              </Grid>
              </Grid>
      </CustomTabPanel>
    </Box>
  );
}