import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import VariantTabs from "./Variant";
import { createproduct, createspare, getproduct } from "../../slices/product";


const AddSpare = ({ setLoader, show }) => {

  const [moreno, setMoreno] = React.useState(1);

  const [variantData, setVariantData] = useState([]);

  const [file, setFile] = useState(null);

  const {vehicles: vehicleData} = useSelector((state) => state.product) || [];

  const moreData = {
    field_id: 1,
    field:"",
    value:""
  }

  const [vehicledata, setVehicledata] = useState({
    product_type:"Spare",
    vehicle_id:"",
    variant_id:1,
    product_name:"",
    product_img: file,
    hsn_no:0,
    item_type:0,
    price:0,
    cgst:0,
    sgst:0,
    igst:0,
    other_details:[]
  })

  useEffect(()=>{
    if(file){
      setVehicledata({...vehicledata, product_img: file})
    }
  },[file])

  useEffect(()=>{
    if(vehicledata.product_type === "Vehicle"){
      navigate('/product/addproduct')
    }
    else if(vehicledata.product_type === "Accessory"){
      navigate('/product/addaccessory')
    }
  }, [vehicledata.product_type])

  const handleFieldChange = (name, field_id, event) => {
    const newValue = event.target.value;
    setVehicledata({ ...vehicledata, other_details: vehicledata.other_details.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          })
  })
}

  const addMoreDetails = () => {
    vehicledata.other_details.push({...moreData, field_id:moreno}) 
    setMoreno(moreno+1);
  }

  const removeField = ( field_id) =>{
    setMoreno(moreno-1);
    setVehicledata( { ...vehicledata, other_details: vehicledata.other_details.filter(item1=>{
              return (item1.field_id !== field_id) ;
          })
      });
    };

    const handleVehicle = (e) => {
      setVehicledata({
        ...vehicledata,
        vehicle_id: e.target.value,
      });
      const variants = vehicleData.filter( va=>{return va.id === e.target.value});
      setVariantData(variants[0].variant);
    }

  const dispatch = useDispatch();

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate('/product');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createspare({...vehicledata, other_details: JSON.stringify(vehicleData.other_details)}))
    .then((data) => {
      setLoader(false);
      navigate("/product");
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Product</Typography>
          </Grid>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={3}>
                <Typography >Product Type *</Typography>
                </Grid>
                <Grid item xs={9}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={vehicledata.product_type}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Vehicle"
                    control={<Radio />}
                    label="Vehicle"
                  />
                  <FormControlLabel
                    value="Spare"
                    control={<Radio />}
                    label="Spare"
                  />
                    <FormControlLabel
                    value="Accessory"
                    control={<Radio />}
                    label="Accessory"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Vehicle *</Typography>
                </Grid>
                <Grid item xs={9}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.vehicle_id}
          onChange={handleVehicle}        >
                    {vehicleData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.id}>{va.product_name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Variant Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.variant_id}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        variant_id: e.target.value,
                      })
                    }        >
                    {variantData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.id}>{va.variant_name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >Product Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Product Name"
                variant="outlined"
                value={vehicledata.product_name}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_name: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >CGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.cgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        cgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >SGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.sgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        sgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >IGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.igst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        igst: e.target.value,
                      })
                    }   
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          <Typography variant="h6">Upload Product Image</Typography>
          <CloudUpload setLoader={setLoader} file={vehicledata.product_img} setFile={setFile}/>
          <Typography color='light' sx={{my: 2}}>Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI Maximum File Size: 1MB</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={12}>
          <Typography variant="h6">Details</Typography>
        </Grid>
        <Grid item xs={3}>
                <Typography>Item Type *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter HSN No"
                variant="outlined"
                value={vehicledata.item_type}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        item_type: e.target.value,
                      })
                    }   
              />
                </Grid>
        <Grid item xs={3}>
                <Typography >HSN No *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter HSN No"
                variant="outlined"
                value={vehicledata.hsn_no}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        hsn_no: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >Price </Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.price}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        price: e.target.value,
                      })
                    }   
              />
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Grid container spacing={2}>
        <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center', mt: 2}}>
        <Grid item xs={12}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'start'}}>
                <Typography variant='h6'>Other Details</Typography>
                <Button variant='text' onClick={()=>addMoreDetails()}>+ Add more details</Button>
                </Box>
                {vehicledata?.other_details?.map((ev, index)=>{
                  return(
                    <Grid key={index} container spacing={2} sx={{mb: 2}}>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.field}
                onChange={(e) => handleFieldChange('field', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.value}
                onChange={(e) => handleFieldChange('value', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Button onClick={()=> removeField( ev.field_id)}>x</Button>
                </Grid>
                </Grid>
                  )
                })}
                </Grid>
       
                </Grid>
                </Grid>
                </Grid>
        <Divider orientation="horizontal" sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Spare</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default AddSpare;
