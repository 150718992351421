import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate, useParams } from "react-router-dom";
import { Stepper, Step, StepLabel } from '@mui/material';
import { getpurchasebyid, updatepurchasebyid, updatepurchasebysecret } from "../../slices/orders";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import Modal from "@mui/material/Modal";
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getproduct } from "../../slices/product";
import { getaccessorystock, getproductstock, getsparestock } from "../../slices/inventory";
import CustomTable2 from "../../components/table/Table2";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const columns = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'vechile_stocks_id', label: 'Inventory No', minWidth: 150, align:'start' },
  { id: 'chasis_no', label: 'Chassis No', minWidth: 150, align:'center' },
  { id: 'motor_no', label: 'Motor No', minWidth: 150, align:'center' },
  { id: 'battery_no', label: 'Battery No', minWidth: 150, align:'center' },
  { id: 'controller_no', label: 'Controller No', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{delete: true}] },
];

const columnss = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'vechile_stocks_id', label: 'Inventory No', minWidth: 150, align:'start' },
  { id: 'chasis_no', label: 'Chassis No', minWidth: 150, align:'center' },
  { id: 'motor_no', label: 'Motor No', minWidth: 150, align:'center' },
  { id: 'battery_no', label: 'Battery No', minWidth: 150, align:'center' },
  { id: 'controller_no', label: 'Controller No', minWidth: 150, align:'center' },
];

const columns1 = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'product', label: 'product', minWidth: 150, align:'start' },
  { id: 'company', label: 'company', minWidth: 150, align:'center' },
  { id: 'part_code', label: 'Part No', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{delete: true}] },
];

const columns11 = [
  { id: 'no', label: '#', minWidth: 150, align:'start' },
  { id: 'product', label: 'product', minWidth: 150, align:'start' },
  { id: 'company', label: 'company', minWidth: 150, align:'center' },
  { id: 'part_code', label: 'Part No', minWidth: 150, align:'center' },
];

const Purchasedetail = ({ setLoader, show }) => {

  const [open, setOpen] = React.useState(false);

  const [qtyEdit, setQtyedit] = useState(false);

  const [name, setName] = useState({
    vehicle_name:"",
    variant_name:""
  });

  const [editValue, setEditValue] = useState({
    po_secret:"",
    ordered_color:"",
    ordered_qty:"",
    remarks:""
  });

  const [selecItem, setSelectItem] = useState([]);

  console.log(selecItem);

  const selectStock = {
    stock_id:"",
    vehicle_id:"",
    variant_id:"",
    vechile_stocks_id:"",
    chasis_no:"",
    motor_no:"",
    battery_no:"",
    controller_no:""
  }

  const [assignedproduct, setAssignedproduct] = useState();

  const [selectedItem, setSelectedItem] = useState([]);

  console.log(selectedItem);

  const {vehicles: vehicleApiData} = useSelector((state) => state.product) || [];

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];

  const {spares: spareStockData} = useSelector((state) => state.inventory) || [];

  const {accessories: accessoriesStockData} = useSelector((state) => state.inventory) || [];

  const handleOpen = (ec) =>{
    setOpen(true);
    setSelectItem(ec);
    dispatch(getproduct());
    dispatch(getproductstock())
    dispatch(getsparestock())
    dispatch(getaccessorystock())
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }
  const handleClose = () =>{
    setOpen(false);
    setAssignedproduct(null);
  }

  const params = useParams();

  const [activeStep, setActiveStep] = useState(1);

  const dispatch = useDispatch();

  const {detail: purchaseDetail} = useSelector((state) => state.order) || [];


  useEffect(()=>{
    setActiveStep(!purchaseDetail?.order_created ? 0 : !purchaseDetail?.order_approved ? 1 : !purchaseDetail?.payment_done  ? 2 : !purchaseDetail?.payment_verification ? 3 : !purchaseDetail?.assign_products ? 4 : !purchaseDetail?.dispatch_products ? 5 :
    !purchaseDetail?.out_for_delivery ? 6 : 7)
  }, [purchaseDetail])

  useEffect(()=>{
    setLoader(true)
      dispatch(getpurchasebyid(params.purchaseID))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  const variantData = (vehicleStockData.filter(sa => (sa.vehicle_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.id) && (sa.variant_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.variant?.filter(va => (va.variant_name === selecItem?.variant_name))[0]?.id))).map( vas => vas.products.filter (abc => abc.color === selecItem.approved_color)).flat()


  const variantData2 = spareStockData.filter(sa => (sa.vehicle_id === selecItem?.vehicle_id && sa.variant_id === selecItem?.variant_id)).map( ab => ab.spares).flat().filter(va => va.product === selecItem?.product_name)


  const variantData3 = accessoriesStockData.filter(sa => (sa.vehicle_id === selecItem?.vehicle_id && sa.variant_id === selecItem?.variant_id)).map( ab => ab.accessory).flat().filter(va => va.product === selecItem?.product_name)

  console.log(selecItem);

  console.log(spareStockData.filter(sa => (sa.vehicle_id === selecItem?.vehicle_id && sa.variant_id === selecItem?.variant_id)).map( ab => ab.spares).flat().filter(va => va.product === selecItem?.product_name));

  
  // console.log(vehicleStockData.filter(sa => (sa.vehicle_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.id) && (sa.variant_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.variant?.filter(va => (va.variant_name === selecItem?.variant_name))[0]?.id)));

  // console.log( ((vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))))

  // console.log( ((vehicleApiData.filter(obj => obj.product_name === name?.vehicle_name))[0]?.variant?.filter(va => (va.variant_name === name?.variant_name))[0].color_details))

  const colorDetails = (vehicleApiData.filter(obj => obj.product_name === name?.vehicle_name))[0]?.variant?.filter(va => (va.variant_name === name?.variant_name))[0].color_details
  const navigate = useNavigate();

  console.log( (vehicleStockData.filter(sa => (sa.vehicle_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.id) && (sa.variant_id === (vehicleApiData.filter(obj => obj.product_name === selecItem?.vechile_name))[0]?.variant?.filter(va => (va.variant_name === selecItem?.variant_name))[0]?.id))));


  const arrowBack = () => {
    navigate(-1);
  };

  const handlePO = (e) => {
    e.preventDefault();
    const formData = {order_approved: true,
      order_approved_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`, item_list: purchaseDetail.item_list.map((vaq)=>{return {...vaq, approved_color: vaq.ordered_color, approved_qty: vaq.ordered_qty }})};
    const p_id = params.purchaseID
    setLoader(true)
    dispatch(updatepurchasebyid({p_id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  const handlepaymentVerify = (e) => {
    e.preventDefault();
    const formData = {payment_verification: true,
      payment_verification_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`};
    const p_id = params.purchaseID
    setLoader(true)
    dispatch(updatepurchasebyid({p_id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  const handleAssign = (ec, vehicle_id, variant_id, vehicle_name) => {
    console.log(ec);
    setAssignedproduct({...selectStock, 
      id: ec.id,
      vechile_name: vehicle_name,
      stock_id: ec.id, 
      vehicle_id:vehicle_id,
    variant_id:variant_id ,
      vechile_stocks_id: ec.vechile_stocks_id,
    chasis_no: ec.chasis_no,
    motor_no: ec.motor_no,
    battery_no: ec.battery_no,
    controller_no: ec.controller_no})
  }

  const handleAssign2 = (ec, vehicle_id, variant_id, vehicle_name) => {
    console.log(ec);
    setAssignedproduct({...selectStock, 
      id: ec.id,
      vechile_name: vehicle_name,
      stock_id: ec.id, 
      vehicle_id:vehicle_id,
    variant_id:variant_id ,
      vechile_stocks_id: ec.vechile_stocks_id,
    ...ec})
  }

  console.log(selectedItem);

  const handlePurchaseEdit = (id, vechile_name, variant_name, color) => {
    setQtyedit(id);
    setName({vehicle_name: vechile_name, variant_name: variant_name});
    setEditValue({...editValue, ordered_color: color});
  }

  const handleSubmit = () => {
    selectedItem.push(assignedproduct)
    handleClose();
  }

  const handleAssignProducts = (e) => {
    e.preventDefault();
    const formData = {assign_products: true,
      assign_products_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`, assign_products_list: JSON.stringify(selectedItem) };
    const p_id = params.purchaseID
    setLoader(true)
    dispatch(updatepurchasebyid({p_id, formData}))
    .then(() => {
      setLoader(false);
      setSelectedItem([]);
    })
    .catch(()=>{
      setLoader(false);
    })  
  }

  const handleDispatch = (e) => {
    e.preventDefault();
    const formData = {dispatch_products: true,
      dispatch_products_date: `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}` };
    const p_id = params.purchaseID
    setLoader(true)
    dispatch(updatepurchasebyid({p_id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })  
  }

  const updateValues = (id) => {

    const index = purchaseDetail?.item_list?.findIndex(item => item.cart_id === id);
    
    const updatedItems = [...purchaseDetail?.item_list];
    // If the item is found
    if (index !== -1) {
      // Create a new array with the updated object at the specific index
      updatedItems[index] = { ...updatedItems[index], ordered_color: editValue.ordered_color, ordered_qty: editValue.ordered_qty};

    }

    const formData = {po_secret: editValue.po_secret ,item_list: updatedItems};
    const p_id = params.purchaseID
    setLoader(true)
    dispatch(updatepurchasebysecret({p_id, formData}))
    .then(() => {
      setLoader(false);
      setQtyedit(false);
    })
    .catch(()=>{
      setLoader(false);
    })  
  }

  useEffect(()=>{
    setLoader(true);
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="div">
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "start" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>{purchaseDetail?.organization_name}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 3 }}>
          <Card sx={{p: 2}}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
          <Grid item xs={2.4}>
                <Typography variant="h6">Dealer ID</Typography>
                <Typography variant="p">{purchaseDetail?.dealer_id}</Typography>
            </Grid>
            <Grid item xs={2.4}>
                <Typography variant="h6">Dealer Name</Typography>
                <Typography variant="p">{purchaseDetail?.dealer_name}</Typography>
            </Grid>
            <Grid item xs={2.4}>
                <Typography variant="h6">Purchase ID</Typography>
                <Typography variant="p">{purchaseDetail?.purchase_id}</Typography>
            </Grid>
            <Grid item xs={2.4}>
                <Typography variant="h6">Purchase Date</Typography>
                <Typography variant="p">{(new Date(purchaseDetail?.created_at)).getDate()} / {(new Date(purchaseDetail?.created_at)).getMonth()+1} / {(new Date(purchaseDetail?.created_at)).getFullYear()}</Typography>
            </Grid>
            <Grid item xs={2.4}>
                <Typography variant="h6">Order Type</Typography>
                <Typography variant="p">{purchaseDetail?.product_type}</Typography>
            </Grid>
          </Grid>
          <Divider orientation="horizontal" sx={{my: 2}} flexItem/>
          <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Typography variant="h5">PO Status</Typography>
                  </Grid>
                  { purchaseDetail?.assign_products && 
                    !purchaseDetail?.dispatch_products &&
                  <Grid item xs={6} sx={{textAlign:'end'}}>
                  <Button onClick={handleDispatch} variant="contained">Dispatch</Button>
                  </Grid>
                  }
                  <Grid item xs={12}>
                  <Stepper activeStep={activeStep} alternativeLabel sx={{my: 5}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>
                          <Typography variant="body1">
                          { label === 'Step 1' && (purchaseDetail?.order_created ? 'Order Created' : 'Order Not Created')} 
                          { label === 'Step 2' && (purchaseDetail?.order_approved
 ? 'Order Approved' : 'Approve Order')}
 { label === 'Step 3' && (purchaseDetail?.payment_done
 ? 'Payment Done' : 'Waiting Payment')}
 { label === 'Step 4' && (purchaseDetail?.payment_verification
 ? 'Payment Verified' : 'Verification')}
 { label === 'Step 5' && (purchaseDetail?.assign_products
 ? 'Product Assigned' : 'Assign Products')}
 { label === 'Step 6' && (purchaseDetail?.dispatch_products
 ? 'Dispatched' : 'Dispatch Products')}
 { label === 'Step 7' && (purchaseDetail?.out_for_delivery
 ? 'Delivered' : 'Out for delivery')}
</Typography>
                        <Typography variant="body2" sx={{mt: 1}}>
                        { label === 'Step 1' && (purchaseDetail?.order_created ? purchaseDetail?.order_created_date : null)} 
                          { label === 'Step 2' && (purchaseDetail?.order_approved ? purchaseDetail?.order_approved_date : null)}
                          { label === 'Step 3' && (purchaseDetail?.payment_done
 ? purchaseDetail?.payment_done_date : null)}
 { label === 'Step 4' && (purchaseDetail?.payment_verification
 ? purchaseDetail?.payment_verification_date : null)}
 { label === 'Step 5' && (purchaseDetail?.assign_products
 ? purchaseDetail?.assign_products_date : null)}
 { label === 'Step 6' && (purchaseDetail?.dispatch_products
 ?  purchaseDetail?.dispatch_products_date : null)}
 { label === 'Step 7' && (purchaseDetail?.out_for_delivery
 ?  purchaseDetail?.out_for_delivery_date : null)}
                        </Typography>
                        </StepLabel>
                        
                    </Step>
                ))}
            </Stepper>              
            </Grid>
            {purchaseDetail?.payment_done &&
                <Grid item xs={12} sx={{mb: 3}}>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Transaction ID
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.transaction_id}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Method
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.payment_method}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Amount
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.amount}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Date
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.transaction_date}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Note
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.transaction?.note}
                        </Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant="h6">
                        Verified
                        </Typography>
                        <Typography variant="p">
                        {purchaseDetail?.payment_verification ? <VerifiedIcon sx={{color:'green'}}/> : 
                        <Button variant="contained" onClick={handlepaymentVerify}>Verify</Button>
                         }
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
          </Grid>
          <Divider orientation="horizontal" sx={{mb: 2}} flexItem/>
          <Grid container spacing={2}>
              <Grid item xs={6}>
                  <Typography variant="h5">Items List</Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign:'end'}}>
                    <Button size="small" variant="text"><CloudDownloadOutlinedIcon sx={{me: 1}}/> Download</Button>
                    <Button size="small" variant="text"><PrintOutlinedIcon/> Print</Button>
              </Grid>
              <Grid item xs={12}>
              {
                purchaseDetail?.item_list.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={1.5}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Vehicle</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.vechile_name}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Variant</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.variant_name}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Ordered color</Typography>
                  <Box sx={{display:'flex', gap:'8px'}}>
                      <p style={{width:'25px', height:'25px', borderRadius:'30px', background:`${ec.ordered_color}`, margin: 0}}></p>
                  </Box>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Ordered Qty</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.ordered_qty}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Price</Typography>
                  <Typography sx={{ fontWeight:'bold'}}>₹ {ec.dealer_price}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                  <Typography variant='body1'>Total Price</Typography>
                  <Typography sx={{ fontWeight:'bold'}}>₹ {ec.total_price}</Typography>
              </Grid>
              {!purchaseDetail?.order_approved &&
              <Grid item xs={1.5}>
                  <Button size="small" onClick={()=>handlePurchaseEdit(ec.cart_id, ec.vechile_name, ec.variant_name, ec.ordered_color)} variant="text">Edit QTY</Button>             
                   </Grid>
              }
              {!purchaseDetail?.order_approved && ec.cart_id === qtyEdit && 
              <Grid item xs={12}>
              <Grid container spacing={2}>
              <Grid item xs={2.4}>
              <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={editValue?.ordered_color}
          onChange={(e)=>{
            setEditValue({
              ...editValue,
              ordered_color: e.target.value
            })
          }}       
                     >
                     {colorDetails && JSON.parse(colorDetails)?.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.code}>{va.name}</MenuItem>
                      )
                     })}
        </Select>
        </FormControl>
              </Grid>
              <Grid item xs={2.4}>
              <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="PO Secret"
                  variant="outlined"
                  value={editValue.po_secret}
                  onChange={(e) =>
                      setEditValue({
                        ...editValue,
                        po_secret: e.target.value,
                      })
                    }
                />
              </Grid>
              <Grid item xs={2.4}>
              <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="QTY"
                  variant="outlined"
                  value={editValue.ordered_qty}
                  onChange={(e) =>
                      setEditValue({
                        ...editValue,
                        ordered_qty: e.target.value,
                      })
                    }
                />
              </Grid>
              <Grid item xs={2.4}>
              <TextField
                   size="small"
                  fullWidth
                  id="outlined-basic"
                  placeholder="Remarks"
                  variant="outlined"
                  value={editValue.remarks}
                  onChange={(e) =>
                      setEditValue({
                        ...editValue,
                        remarks: e.target.value,
                      })
                    }
                />
              </Grid>
              <Grid item xs={2.4}>
                <Button size="small" onClick={() => updateValues(ec.cart_id)} variant="contained">Update</Button>
              </Grid>
              </Grid>
              </Grid>
              }
              {!purchaseDetail.assign_products && purchaseDetail?.payment_verification &&
              <Grid item xs={1.5}>
                  {ec.ordered_qty !== selectedItem?.filter(obj =>{return obj?.vechile_name === ec?.vechile_name}).length ? <Button onClick={() => handleOpen(ec)} variant="contained" size="small">Assign Item</Button> :
                 null } 
                  <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                      {purchaseDetail.product_type === "Vehicle" ?
                        <Box sx={style}>
                          <Grid container spacing={2}>
                              <Grid item xs={4}>
                                  {selecItem.vechile_name}
                              </Grid>
                              <Grid item xs={4}>
                                  {selecItem.variant_name}
                              </Grid>
                              <Grid item xs={4} sx={{display:'flex', gap: 1}}>
                              <p key={index} style={{width:'25px', height:'25px', borderRadius:'30px', background:`${selecItem.ordered_color}`, margin: 0}}></p>
                              <Typography variant="p">
                                {((vehicleApiData?.filter(obj => obj?.product_name === selecItem?.vechile_name))[0]?.variant?.filter(va => (va?.variant_name === selecItem?.variant_name))[0]?.color_details) && JSON.parse((vehicleApiData?.filter(obj => obj?.product_name === selecItem?.vechile_name))[0]?.variant?.filter(va => (va?.variant_name === selecItem?.variant_name))[0]?.color_details).filter(eb => eb.code === selecItem.ordered_color)[0].name}
                              </Typography>
                              </Grid>
                          </Grid>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box>
                                  {
                                    variantData.filter(obj => !(selectedItem.map(va => va.id).flat()).includes(obj.id)).map((ec, index)=>{
          return(
            <Card onClick={()=> handleAssign(ec, selecItem.vehicle_id, selecItem.variant_id, selecItem.vechile_name)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2, borderColor: assignedproduct?.vechile_stocks_id === ec.vechile_stocks_id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <Typography variant='body1'>Chassis No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.chasis_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Motor</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.motor_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Battery</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.battery_no}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Controller</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.controller_no}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" onClick={handleSubmit}>
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        :
                        purchaseDetail.product_type === "Spare" ?
                        <Box sx={style}>
                          <Grid container spacing={2}>
                              <Grid item xs={4}>
                                  {selecItem.vechile_name}
                              </Grid>
                              <Grid item xs={4}>
                                  {selecItem.variant_name}
                              </Grid>
                              <Grid item xs={4}>
                                  {selecItem.product_name}
                              </Grid>
                          </Grid>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box>
                                  {
                                    variantData2.filter(obj => !(selectedItem.map(va => va?.id).flat()).includes(obj.id)).map((ec, index)=>{
          return(
            <Card onClick={()=> handleAssign2(ec, selecItem.vehicle_id, selecItem.variant_id, selecItem.vechile_name)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2, borderColor: assignedproduct?.id === ec.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" onClick={handleSubmit}>
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                        :
                        <Box sx={style}>
                          <Grid container spacing={2}>
                              <Grid item xs={4}>
                                  {selecItem.vechile_name}
                              </Grid>
                              <Grid item xs={4}>
                                  {selecItem.variant_name}
                              </Grid>
                              <Grid item xs={4}>
                                  {selecItem.product_name}
                              </Grid>
                          </Grid>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                          <Box>
                                  {
                                    variantData3.filter(obj => !(selectedItem.map(va => va.id).flat()).includes(obj.id)).map((ec, index)=>{
          return(
            <Card onClick={()=> handleAssign2(ec, selecItem.vehicle_id, selecItem.variant_id, selecItem.vechile_name)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2, borderColor: assignedproduct?.id === ec.id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={4}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Company</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.company}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='body1'>Part Code</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.part_code}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
                            <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                              <Button variant="contained" onClick={handleSubmit}>
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      }
                    </Modal>            
                   </Grid>
              }
              {selectedItem.filter(obj =>{return obj.vechile_name === ec.vechile_name}).length > 0 &&
              <CustomTable2 columns={purchaseDetail?.product_type === "Vehicle" ? columns : purchaseDetail?.product_type === "Spare" ? columns1 : columns1 } rows={selectedItem.filter(obj =>{return obj.vechile_name === ec.vechile_name})} setSelectedItem={setSelectedItem} selectedItem={selectedItem}/>
              }
              {purchaseDetail.assign_products &&
              <CustomTable2 columns={purchaseDetail?.product_type === "Vehicle" ? columnss : purchaseDetail?.product_type === "Spare" ? columns11 : columns11 } rows={purchaseDetail?.assign_products_list && JSON.parse(purchaseDetail?.assign_products_list).filter(obj =>{return obj.vechile_name === ec.vechile_name})} setSelectedItem={setSelectedItem} selectedItem={selectedItem}/>
              }
          </Grid>
              
      </Card>
          )
        })
      }
              </Grid>
            </Grid>
            {!purchaseDetail?.order_approved &&
            <Box sx={{display:'flex', justifyContent:'end'}}>
            <Button size="small" variant="contained" onClick={handlePO}>Accept PO</Button>             
            </Box>
            }
            {purchaseDetail?.item_list
                      .map((obj) => Number(obj.ordered_qty))
                      ?.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue,
                        0
                      ) === selectedItem.length ? 
            <Box sx={{display:'flex', justifyContent:'end'}}>
            <Button onClick={ handleAssignProducts} variant="contained" size="small">Confirm Selected Item</Button>
            </Box> : null
            }
          </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Purchasedetail;
