import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createproduct, createspare, getproduct } from "../../slices/product";
import { createproductstock } from "../../slices/inventory";


const AddProductStock = ({ setLoader, show }) => {

  const [moreno, setMoreno] = React.useState(1);

  const [variantData, setVariantData] = useState([]);

  const [colorData, setColorData] = useState([]);

  const {vehicles: vehicleData} = useSelector((state) => state.product) || [];

  const moreData = {
    field_id: 0,
    chasis_no: "",
    mft_date: "",
    color: "",
    motor_no: "",
    battery_no: "",
    convertor_no: "",
    key_no: "",
    controller_no: "",
    meter_no: ""
  }

  const [vehicledata, setVehicledata] = useState({
    product_type:"Vehicle",
    vehicle_id:"",
    variant_id:1,
    products:[moreData]
  })

  useEffect(()=>{
    if(vehicledata.product_type === "Spare"){
      navigate('/inventory/addsparestock')
    }
    else if(vehicledata.product_type === "Accessory"){
      navigate('/inventory/addaccessorystock')
    }
  }, [vehicledata.product_type])

  const handleFieldChange = (name, field_id, event) => {
    const newValue = event.target.value;
    setVehicledata({ ...vehicledata, products: vehicledata.products.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          })
  })
}

  const addMoreDetails = () => {
    vehicledata.products.push({...moreData, field_id:moreno}) 
    setMoreno(moreno+1);
  }

  const removeField = ( field_id) =>{
    setMoreno(moreno-1);
    setVehicledata( { ...vehicledata, products: vehicledata.products.filter(item1=>{
              return (item1.field_id !== field_id) ;
          })
      });
    };

    const handleVehicle = (e) => {
      setVehicledata({
        ...vehicledata,
        vehicle_id: e.target.value,
      });
      const variants = vehicleData.filter( va=>{return va.id === e.target.value});
      setVariantData(variants[0].variant);
    }

    const handleVariant = (e) => {
      setVehicledata({
        ...vehicledata,
        variant_id: e.target.value,
      });
      const colors = variantData.filter( va=>{return va.id === e.target.value});
      console.log(colors);
      setColorData(JSON.parse(colors[0].color_details).map(obj =>{return {code : obj.code, name: obj.name}}));
    }

    useEffect(()=>{
      if(vehicledata.variant_id !== ""){
      const colors = variantData.filter( va=>{return va.id === vehicledata.variant_id});
      console.log(colors);
      colors.length > 0 && setColorData(JSON.parse(colors[0]?.color_details).map(obj => {return {code : obj.code, name: obj.name}}));
      }
    }, [vehicledata.variant_id, vehicledata.vehicle_id])

  const dispatch = useDispatch();

  console.log(vehicledata);

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate('/inventory');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createproductstock({...vehicledata}))
    .then((data) => {
      setLoader(false);
      navigate("/inventory");
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>Add Stock</Typography>
          </Grid>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={3}>
                <Typography >Product Type *</Typography>
                </Grid>
                <Grid item xs={9}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={vehicledata.product_type}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Vehicle"
                    control={<Radio />}
                    label="Vehicle"
                  />
                  <FormControlLabel
                    value="Spare"
                    control={<Radio />}
                    label="Spare"
                  />
                    <FormControlLabel
                    value="Accessory"
                    control={<Radio />}
                    label="Accessory"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Vehicle Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.vehicle_id}
          onChange={handleVehicle}       
                     >
                    {vehicleData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.id}>{va.product_name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Variant Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.variant_id}
          onChange={handleVariant}        >
                    {variantData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.id}>{va.variant_name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider/>
                <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={12}>
                <Box sx={{display:'flex', justifyContent:'end', alignItems:'start'}}>
                <Button variant='text' onClick={()=>addMoreDetails()}>+ Add Product</Button>
                </Box>
                {vehicledata?.products?.map((ev, index)=>{
                  return(
                    <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", p: 2, mb: 2}}>
                    <Grid key={index} container spacing={2} sx={{alignItems:'center'}}>
                    <Grid item xs={6}>
                    <Typography variant="h6">
                    Product Details
                    </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end'}}>
                <Button onClick={()=> removeField( ev.field_id)}>x Remove</Button>
                    </Grid>
                    <Grid item xs={1}>
                <Typography>Chassis No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.chasis_no}
                onChange={(e) => handleFieldChange('chasis_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>MFT.Date</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                type="date"
                id="outlined-basic"
                variant="outlined"
                value={ev?.mft_date}
                onChange={(e) => handleFieldChange('mft_date', ev.field_id, e)}
              />
                </Grid>
                    <Grid item xs={1}>
                <Typography>Color</Typography>
                </Grid>
                <Grid item xs={3}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={ev?.color}
          onChange={(e) => handleFieldChange('color', ev.field_id, e)}        >
                    {colorData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.code}>{va.name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                    Spare Details
                    </Typography>
                    </Grid>
                    <Grid item xs={1}>
                <Typography>Motor No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.motor_no}
                onChange={(e) => handleFieldChange('motor_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Battery No</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.battery_no}
                onChange={(e) => handleFieldChange('battery_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Controller No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.controller_no}
                onChange={(e) => handleFieldChange('controller_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Converter No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.convertor_no}
                onChange={(e) => handleFieldChange('convertor_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Meter No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.meter_no}
                onChange={(e) => handleFieldChange('meter_no', ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography>Key No</Typography>
                </Grid>
                    <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.key_no}
                onChange={(e) => handleFieldChange('key_no', ev.field_id, e)}
              />
                </Grid>
                </Grid>
                </Card>
                  )
                })}
                </Grid>
       
                </Grid>
                </Grid>
                </Grid>
        <Divider orientation="horizontal" sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Add Stock</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default AddProductStock;
