import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Card, CardContent, Grid, colors } from '@mui/material';
import no_data from '../../assets/images/no_data.png';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: 'vehicle_id', label: 'Vehicle_Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'item_type', label: 'Type', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns1 = [
  { id: 'vehicle_id', label: 'Vehicle_Name', minWidth: 150, align:'start' },
  { id: 'product_name', label: 'Name', minWidth: 150, align:'start' },
  { id: 'company', label: 'Company', minWidth: 150, align:'center' },
  { id: 'variant_id', label: 'Variant', minWidth: 150, align:'center' },
  { id: 'hsn_no', label: 'HSN Code', minWidth: 150, align:'center' },
  { id: 'price', label: 'Price', minWidth: 150, align:'center' },
  { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({ value, setValue, vehicleApiData, spareApiData, accessoriesApiData, vehicleStockData, spareStockData, accessoriesStockData}) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const rows1 = vehicleStockData?.map((va)=>{
    return{
      product_img: vehicleApiData.filter(obj => obj.id === va.vehicle_id)[0]?.product_img,
      product_name: vehicleApiData.filter(obj => obj.id === va.vehicle_id)[0]?.product_name,
      variant_name: (vehicleApiData.filter(obj => obj.id === va.vehicle_id)[0]?.variant)?.filter(vaa => vaa.id === va.variant_id )[0]?.variant_name,
      colours: va?.products?.map(obj => obj.color).flat(),
      // colours: vehicleApiData ? JSON.parse(((vehicleApiData?.filter(obj => obj.id === va.vehicle_id)[0]?.variant)?.filter(vaa => vaa.id === va.variant_id )[0]?.color_details) || "[{}]")?.map(obj => obj.code) : [],
      stocks: va?.products?.length,
      availability: "Available"
    }
  });

  const spareProducts = [...new Set((spareStockData?.map((item, index) =>{return item.spares?.map(obj => obj.product)}))?.flat())];

  console.log(spareProducts, spareStockData, spareApiData);

  const rows2 = spareProducts.map((va)=>{
    return{
      product_img: spareApiData?.filter(ab => ab.product_name === va)[0]?.product_img,
      product_name: va,
      variant_name: spareApiData.filter(ab => ab.product_name === va)[0]?.variant?.variant_name,
      stocks: ((spareStockData.filter(obj => (obj.spares)?.filter(ob => ob.product === va))).map(va => va.spares)).flat().length
    }
  });

  console.log(rows2)

  const accessoryProducts = [...new Set((accessoriesStockData?.map((item, index) =>{return item.accessory?.map(obj => obj.product)}))?.flat())];

  const rows3 = accessoryProducts.map((va)=>{
    return{
      product_img: accessoriesApiData?.filter(ab => ab.product_name === va)[0]?.product_img,
      product_name: va,
      variant_name: accessoriesApiData.filter(ab => ab.product_name === va)[0]?.variant?.variant_name,
      stocks: ((accessoriesStockData.filter(obj => (obj.accessory)?.filter(ob => ob.product === va))).map(va => va.accessory)).flat().length
    }
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Vehicle Model" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
          <Tab label="Spare Parts" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Accessory" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {rows1.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows1.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={2}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Variant Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.variant_name}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Colours</Typography>
                  <Box sx={{display:'flex', gap:'8px'}}>
                  {ec?.colours?.map((ka, index)=>{
                    return(
                      <p key={index} style={{width:'25px', height:'25px', borderRadius:'30px', background:`${ka}`, margin: 0}}></p>
                    )
                  })}
                  </Box>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Availability</Typography>
                  <Typography sx={{color: '#2e7d32', fontWeight:'bold'}}>{ec.availability}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {rows2.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows2.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Variant Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.variant_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {rows3.length === 0 ?
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' justifyContent="center">
        <img src={no_data} alt='no_data'></img>
        </Grid>
      </Grid>
      :
        rows3.map((ec, index)=>{
          return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={3}>
                  <img src={ec.product_img} alt='img' width={60}/>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Product Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.product_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Variant Name</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.variant_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Stocks</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{ec.stocks}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }        </CustomTabPanel>
    </Box>
  );
}