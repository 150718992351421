import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import BasicTabs from '../../components/tabs/Tabs2';
import { Link } from 'react-router-dom';
import { getorders } from '../../slices/orders';

const Orders = ({setLoader, show}) => {

  const [value, setValue] = React.useState(0);

  const {orders: vehicleApiData} = useSelector((state) => state.order) || [];

  const {orders: spareApiData} = useSelector((state) => state.order) || [];

  const {orders: accessoriesApiData} = useSelector((state) => state.order) || [];

  const dispatch = useDispatch();

  useEffect(()=>{
    setLoader(true)
      dispatch(getorders())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
             Received Orders
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>  
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <BasicTabs vehicleApiData={vehicleApiData} spareApiData={spareApiData} accessoriesApiData={accessoriesApiData} value={value} setValue={setValue}/>
      </Box>
    </div>
  );
}

export default Orders;