import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import VariantTabs from "./Variant";
import { createproduct, createvariant } from "../../slices/product";


const AddProduct = ({ setLoader, show }) => {

  const [file, setFile] = useState(null);


  const [vehicledata, setVehicledata] = useState({
    product_type:"Vehicle",
    product_name:"",
    product_img:file,
    type:"2 Wheeler",
    registration:'No',
    hsn_no:0,
    mft_year:0,
    cgst:0,
    sgst:0,
    igst:0
  })

  const priceGroups = {
    state:"",
    ex_showroom:"",
    dealer:""
  }
  
  const colorGroups = {
    color_id: 1,
    name:"Black",
    code:"#000"
  }
  
  const moreData = {
    field_id: 1,
    field:"",
    value:""
  }

  
  const variantIntiData = {
    vehicle_id: 6,
    variant_name: "",
    released_date: "",
    subsidy: "",
    motor_name: "",
    motor_type: "",
    motor_voltage: "",
    motor_capacity: "",
    motor_warranty_km: "",
    motor_warranty_months: "",
    motor_warranty_type: "",
    battery_name: "",
    battery_type: "",
    battery_lifecycle: "",
    battery_capacity: "",
    battery_warranty_km: "",
    battery_warranty_months: "",
    battery_warranty_type: "",
    charger_name: "",
    charger_capacity: "",
    charger_type: "",
    charger_warranty_km: "",
    charger_warranty_months: "",
    charger_warranty_type: "",
    converter_name: "",
    converter_capacity: "",
    converter_warranty_km: "",
    converter_warranty_months: "",
    converter_warranty_type: "",
    controller_name: "",
    controller_capacity: "",
    controller_warranty_km: "",
    controller_warranty_months: "",
    controller_warranty_type: "",
    display_name: "",
    display_type: "",
    display_warranty_km: "",
    display_warranty_months: "",
    display_warranty_type: "",
    color_details: [colorGroups],
    other_details: [],
    price_groups: []
   }


   const [variantData, setVariantData] = React.useState([variantIntiData]);

   console.log(variantData);

  useEffect(()=>{
    if(file){
      setVehicledata({...vehicledata, product_img: file})
    }
  },[file])

  useEffect(()=>{
    if(vehicledata.product_type === "Spare"){
      navigate('/product/addspare')
    }
    else if(vehicledata.product_type === "Accessory"){
      navigate('/product/addaccessory')
    }
  }, [vehicledata.product_type])


  const dispatch = useDispatch();

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate('/product');
  };

  function isEmptyString(value) {
    return typeof value === 'string' && value.trim() === '';
}

function hasEmptyString() {
  for (let i = 0; i < variantData.length; i++) {
      const obj = variantData[i];
      for (const key in obj) {
          if (obj.hasOwnProperty(key) && isEmptyString(obj[key])) {
              return true
          }
      }
  }
  return false;
}

  const handleSubmit = (e) => {
    e.preventDefault();
    if(hasEmptyString() || variantData?.priceGroups?.length === 0){
      window.alert("Please fill out all the fields !!!")
    }
    else{
      setLoader(true);

      dispatch(createproduct({...vehicledata}))
      .then((data) => {
        const variantUpData = variantData.map((item, index)=>{
          return {...item, vehicle_id: data.payload.productdata.data.id, color_details: JSON.stringify(variantData[index].color_details), price_groups: JSON.stringify(variantData[index].price_groups), other_details: JSON.stringify(variantData[index].other_details)}
        })
        dispatch(createvariant({data: variantUpData}))
        .then(() => {
          navigate("/product");
          setLoader(false);
       })
        .catch(()=>{
          setLoader(false);
        })
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  };

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Product</Typography>
          </Grid>
          <Grid item xs={8} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={3}>
                <Typography >Product Type *</Typography>
                </Grid>
                <Grid item xs={9}>
                <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={vehicledata.product_type}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_type: e.target.value,
                      })
                    }
                >
                  <FormControlLabel
                    value="Vehicle"
                    control={<Radio />}
                    label="Vehicle"
                  />
                  <FormControlLabel
                    value="Spare"
                    control={<Radio />}
                    label="Spare"
                  />
                    <FormControlLabel
                    value="Accessory"
                    control={<Radio />}
                    label="Accessory"
                  />
                </RadioGroup>
              </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >Product Name *</Typography>
                </Grid>
                <Grid item xs={9}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter Product Name"
                variant="outlined"
                value={vehicledata.product_name}
                onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        product_name: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={3}>
                <Typography>Type *</Typography>
                </Grid>
                <Grid item xs={3}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.type}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        type: e.target.value,
                      })
                    }        >
            <MenuItem value="2 Wheeler">2 Wheeler</MenuItem>
            <MenuItem value="3 Wheeler">3 Wheeler</MenuItem>
            <MenuItem value="4 Wheeler">4 Wheeler</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography>Registration *</Typography>
                </Grid>
                <Grid item xs={3}>
                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.registration}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        registration: e.target.value,
                      })
                    } 
        >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={3}>
                <Typography >HSN No *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="Enter HSN No"
                variant="outlined"
                value={vehicledata.hsn_no}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        hsn_no: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >MFT. Year </Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.mft_year}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        mft_year: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >CGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.cgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        cgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >SGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.sgst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        sgst: e.target.value,
                      })
                    }   
              />
                </Grid>
                <Grid item xs={3}>
                <Typography >IGST(%) *</Typography>
                </Grid>
                <Grid item xs={3}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.igst}
          onChange={(e) =>
                      setVehicledata({
                        ...vehicledata,
                        igst: e.target.value,
                      })
                    }   
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
          <Typography variant="h6">Upload Product Image</Typography>
          <CloudUpload setLoader={setLoader} file={vehicledata.product_img} setFile={setFile}/>
          <Typography color='light' sx={{my: 2}}>Preferred Image Dimensions: 240 x 240 pixels @ 72 DPI Maximum File Size: 1MB</Typography>
          </Grid>
        </Grid>
        <VariantTabs priceGroups={priceGroups} variantData={variantData} setVariantData={setVariantData} colorGroups={colorGroups} moreData={moreData} variantIntiData={variantIntiData} setLoader={setLoader}/>
        <Divider orientation="horizontal" sx={{mb: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Product</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default AddProduct;
