import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  Card,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createproduct, createspare, getproduct } from "../../slices/product";
import { createchallan } from "../../slices/challan";
import { getdealer } from "../../slices/dealer";


const AddChallan = ({ setLoader, show }) => {

  const [moreno, setMoreno] = React.useState(1);

  const [file, setFile] = React.useState(null)

  const [variantData, setVariantData] = useState([]);

  const {dealers: dealerData} = useSelector((state) => state.dealer) || [];

  const moreData = {
    field_id: 0,
    itemDetails:"",
    quantity:"",
    rate:"",
    amount:"",
    notes:'',
    attachment:''
  }

  const [vehicledata, setVehicledata] = useState({
    dealer_name: "",
    dealer_id: "",
    delivery_challan: "",
    challan_type: "",
    reference: "",
    delivery_challan_date: "",
    customer_notes: "",
    sub_total: "",
    adjustment: 0.00,
    total_price: "",
    items:[moreData]
  })

  const handleFieldChange = (name, field_id, event) => {
    const newValue = event.target.value;
    setVehicledata({ ...vehicledata, items: vehicledata.items.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          })
  })
}

console.log(vehicledata);

  const addMoreDetails = () => {
    vehicledata.items.push({...moreData, field_id:moreno}) 
    setMoreno(moreno+1);
  }

  const removeField = ( field_id) =>{
    setMoreno(moreno-1);
    setVehicledata( { ...vehicledata, items: vehicledata.items.filter(item1=>{
              return (item1.field_id !== field_id) ;
          })
      });
    };

    const handleVehicle = (e) => {
      setVehicledata({
        ...vehicledata,
        dealer_id: e.target.value,
        dealer_name: (dealerData.filter(obj =>{return obj.dealer_id === e.target.value}))[0].first_name
      });
    }

    const handleValue = (e, name) => {
      setVehicledata({
        ...vehicledata,
        [name]: e.target.value,
      });
    }



  const dispatch = useDispatch();

  const navigate = useNavigate();


  const arrowBack = () => {
    navigate(-1);
  };

  const subTotal = vehicledata?.items?.map((obj) => Number(obj.rate * obj.quantity))
  ?.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue,
    0
  );

  const totalAmount = subTotal - Number(vehicledata.adjustment);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createchallan({...vehicledata, items: JSON.stringify(vehicledata.items), sub_total: subTotal, total_price: totalAmount, attachment: file}))
    .then((data) => {
      setLoader(false);
      navigate("/deliverychallan");
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  const generateRandomString = () => {
    const length = 10; // specify the length of the random string
    const characters = '0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  useEffect(()=>{
    setVehicledata({...vehicledata, delivery_challan:`DLCH${generateRandomString()}`})
    setLoader(true)
      dispatch(getdealer())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "end" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Delivery Challan</Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: 3 }}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={4}>
                <Typography>Dealer Name *</Typography>
                </Grid>
                <Grid item xs={8}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.dealer_id}
          onChange={handleVehicle}       
                     >
                    {dealerData.map((va, index)=>{
                      return(
                        <MenuItem key={index} value={va.dealer_id}>{va.first_name}</MenuItem>
                      )
                    })}
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={4}>
                <Typography>Delivery Challan #*</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.delivery_challan}
                InputProps={{
            readOnly: true,
                }}
                onChange={(e)=>handleValue(e, 'delivery_challan')}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography>Challan Type *</Typography>
                </Grid>
                <Grid item xs={8}>
                                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vehicledata.challan_type}
          onChange={(e)=>handleValue(e, 'challan_type')}

                >
                        <MenuItem value="Sale Delivery">Sale Delivery</MenuItem>
                        <MenuItem value="Sale">Sale</MenuItem>
                        <MenuItem value="Service">Service</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                <Grid item xs={4}>
                <Typography>Reference#</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.reference}
          onChange={(e)=>handleValue(e, 'reference')}
              />
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
          <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={4}>
                <Typography>Delivery Challan Date *</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                type="date"
                id="outlined-basic"
                variant="outlined"
                value={vehicledata.delivery_challan_date}
          onChange={(e)=>handleValue(e, 'delivery_challan_date')}
              />
                </Grid>
          </Grid>
          </Grid>
        </Grid>
        <Divider sx={{my: 1}}/>
                <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2} sx={{alignItems:'center'}}>
        <Grid item xs={12}>
                <Grid container spacing={2} sx={{bgcolor:'#eaeaea', p: 2, pt: 0}}>
                  <Grid item xs={5} >
                    <Typography variant="p">Item Details</Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Typography variant="p">Quantity</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="p">Rate</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="p">Amount</Typography>
                  </Grid>
                </Grid>
                {vehicledata?.items?.map((ev, index)=>{
                  return(
                    <Card key={index} sx={{ boxShadow: "none", borderBottom: "1px solid #bababa", p: 2}}>
                    <Grid key={index} container spacing={2} sx={{alignItems:'center'}}>
                    <Grid item xs={5}>
                    <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.itemDetails}
                onChange={(e) => handleFieldChange('itemDetails', ev.field_id, e)}
              />
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.quantity}
                onChange={(e) => handleFieldChange('quantity', ev.field_id, e)}
              />
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.rate}
                onChange={(e) => handleFieldChange('rate', ev.field_id, e)}
              />
                    </Grid>
                    <Grid item xs={2}>
                    <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.quantity * ev?.rate || null}
                inputProps={{readOnly: true}}
                onChange={(e) => handleFieldChange('amount', ev.field_id, e)}
              />
                    </Grid>
                    <Grid item xs={1} sx={{textAlign:'end'}}>
                <Button variant="outlined" onClick={()=> removeField( ev.field_id)}>x</Button>
                    </Grid>
                </Grid>
                </Card>
                  )
                })}
                </Grid>
       
                </Grid>
                </Grid>
                </Grid>
                <Box sx={{display:'flex', justifyContent:'start', alignItems:'start', my: 2}}>
                <Button variant='text' onClick={()=>addMoreDetails()}>+ Add Another Line</Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                      <Typography variant="h6" sx={{mb: 1}}>
                        Customer Notes
                      </Typography>
                      <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="We will displayed on the estimate"
                variant="outlined"
                multiline
                rows={3}
                value={vehicledata.customer_notes}
          onChange={(e)=>handleValue(e, 'customer_notes')}
              />
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{boxShadow: 'none', bgcolor:'#eaeaea', border:'1px solid #eaeaea', p: 2}}>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <Typography variant="p">Sub Total</Typography>
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'end'}}>
                              <Typography variant="p">{subTotal}</Typography>
                          </Grid>
                          <Grid item xs={6} sx={{display:'flex', justifyContent:'start', alignItems:'center', gap: 1}}>
                              <Typography variant="body1" sx={{p: 0}}>Adjustment</Typography>
                              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                sx={{bgcolor:'#fff'}}
                value={vehicledata.adjustment}
          onChange={(e)=>handleValue(e, 'adjustment')}
              />
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'end'}}>
                              <Typography variant="p">{vehicledata.adjustment}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                              <Typography variant="h6">Total(₹)</Typography>
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'end'}}>
                              <Typography variant="h6">{totalAmount}</Typography>
                          </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card sx={{boxShadow: 'none', bgcolor:'#eaeaea', border:'1px solid #eaeaea', p: 2}}>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <Typography variant="p" >Notes
(Internal use. Not visible to customer)</Typography>
<TextField
                size="small"
                fullWidth
                id="outlined-basic"
                placeholder="We will displayed on the estimate"
                variant="outlined"
                multiline
                rows={3}
                value={vehicledata.notes}
                onChange={(e)=>handleValue(e, 'notes')}
                sx={{mt: 2, bgcolor:'#fff'}}
              />
                          </Grid>
                          <Grid item xs={6}>
                              <Typography variant="p">Attach File(s) to estimate</Typography>
                              <CloudUpload setLoader={setLoader} file={file} setFile={setFile}/>
                          </Grid>
                          <Divider orientation="horizontal" sx={{my: 3}}/>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
        <Divider orientation="horizontal" sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Delivery Challan</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default AddChallan;
