import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import BasicTabs from '../../components/tabs/VehicleTab';
import { Link } from 'react-router-dom';
import { getaccessory, getproduct, getspare} from '../../slices/product';
import Popup from '../../components/popup/Popup';

const Products = ({setLoader, show}) => {

  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {vehicles: vehicleApiData} = useSelector((state) => state.product) || [];

  const {spares: spareApiData} = useSelector((state) => state.product) || [];

  const {accessories: accessoriesApiData} = useSelector((state) => state.product) || [];


  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.product);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getproduct())
      dispatch(getspare())
      dispatch(getaccessory())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={6} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
             Products
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton placeholder="Search Model"/>
          { value === 0 &&
          <Link to='/product/addproduct'>
          <Button variant='contained' size='large'>+ Add Product</Button>
          </Link>
          }
          {
            value === 1 &&
            <Link to='/product/addspare'>
            <Button variant='contained' size='large'>+ Add Product</Button>
          </Link>
          }
          {
            value === 2 &&
            <Link to='/product/addaccessory'>
            <Button variant='contained' size='large'>+ Add Product</Button>
          </Link>
          }
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <BasicTabs vehicleApiData={vehicleApiData} spareApiData={spareApiData} accessoriesApiData={accessoriesApiData} value={value} setValue={setValue} setLoader={setLoader}/>
      </Box>
    </div>
  );
}

export default Products;