import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deletedealer, getdealerbyid } from "../../slices/dealer";
import BasicTabs from "../../components/tabs/Dealertab";

const Viewdealer = ({ setLoader, show }) => {

  const params = useParams();

  const id = params.dealerId;

  const {dealer: dealerData} = useSelector((state) => state.dealer) || [];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  console.log(dealerData);

  const arrowBack = () => {
    navigate(-1);
  };

  const deleteDealer = (dealer_id) =>{
    if(dealer_id){
      setLoader(true)
      dispatch(deletedealer({dealer_id}))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  }

  useEffect(()=>{
    setLoader(true)
      dispatch(getdealerbyid(id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5"  sx={{fontWeight:'bold'}}>Dealer Details</Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ paddingTop: 3, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6">
               {dealerData?.organization_name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ paddingTop: 3, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6">
            Dealer ID: {dealerData?.dealer_id}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ paddingTop: 3, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6">
               GST No: {dealerData?.gst_no}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "end" }}>
          <Link to={`/dealer/update/${id}`} state={{viewData: dealerData}}>
          <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 1,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
                                        </Link>
                                        <Button
                                          onClick={()=> deleteDealer(id)}
                                          sx={{
                                            background: "#dc0707",
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Dealer Name</Typography>
            <Typography variant="body1">{dealerData?.first_name} {dealerData?.last_name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Email ID</Typography>
            <Typography variant="body1">{dealerData?.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Location</Typography>
            <Typography variant="body1">{dealerData?.zone}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Created On</Typography>
            <Typography variant="body1">{`${new Date(dealerData?.created_at).getDate()}-${new Date(dealerData?.created_at).getMonth()+1}-${new Date(dealerData?.created_at).getFullYear()}`}</Typography>
            </Grid>
            <Grid item xs={2}>
            <Typography variant="body2">Status</Typography>
            <Typography variant="body1">Active</Typography>
            </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <Box>
            <a href={`tel:${9876}`}>
              <Button
                sx={{
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                  border:'1px solid #3d07dc'
                }}
              >
                <CallIcon sx={{ color: "#3d07dc", fontSize: 17 }}></CallIcon>
              </Button>
              </a>
              <a href={`mailto:${545}`}>
              <Button
                sx={{
                  border:'1px solid #3d07dc',
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                }}
              >
                <MessageIcon sx={{ color: "#3d07dc", fontSize: 17 }}></MessageIcon>
              </Button>
              </a>
              <a href={`mailto:${65464}`}>
              <Button
                sx={{
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                  border:'1px solid #3d07dc',
                }}
              >
                <EmailIcon sx={{ color: "#3d07dc", fontSize: 17 }}></EmailIcon>
              </Button>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BasicTabs cusId={id} viewData={dealerData} setLoader={setLoader}/>
            </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Viewdealer;
